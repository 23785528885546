<template>
  <div>
    <Breadcrumbs main="Голосование" title="Редактирование голосования"></Breadcrumbs>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Редактирование голосования</h5>
            </div>
            <div class="card-body">
              <b-card>
                <ValidationObserver ref="form">
                  <b-form @submit.prevent="submitVote">
                    <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
                      <b-tab v-for="lang in languages"
                             :title="lang.language"
                             :key="'lang-' + lang.id"
                             @click="getLanguageFromBtn(lang.language.toLowerCase())"
                             nav-class="mr-2 mb-2">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img :src="lang.img" width="20" height="20" class="mr-2">
                            <span>{{ lang.language }}</span>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>

                    <div class="mt-3">
                      <label class="font-weight-bold star">Название опроса {{ language.toUpperCase() }}</label>
                      <ValidationProvider name="Название опроса" rules="required|min:2" v-slot="{ errors }">
                        <b-form-input
                          v-model="voteData.title[language]"
                          :placeholder="'Введите название на ' + language.toUpperCase()"
                          :state="!errors.length ? null : false"
                        ></b-form-input>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-3">
                      <label class="font-weight-bold star">Описание {{ language.toUpperCase() }}</label>
                      <ValidationProvider name="Описание" rules="required|min:2" v-slot="{ errors }">
                        <b-form-textarea
                          v-model="voteData.description[language]"
                          :placeholder="'Введите описание на ' + language.toUpperCase()"
                          :state="!errors.length ? null : false"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold">Варианты ответа (Команды)</label>
                      <div v-for="(team, index) in selectedTeams" :key="index" class="d-flex mb-2">
                        <b-form-input
                          v-model="team.name"
                          readonly
                        ></b-form-input>
                        <b-button variant="danger" @click="removeTeam(index)" class="ml-2">X</b-button>
                      </div>
                      <div class="mt-2">
                        <b-form-input
                          v-model="teamSearch"
                          placeholder="Поиск команд"
                          @input="searchTeams"
                        ></b-form-input>
                        <b-list-group v-if="searchResults.length" class="mt-2">
                          <b-list-group-item
                            v-for="team in searchResults"
                            :key="team.id"
                            @click="addTeam(team)"
                            href="#"
                            class="d-flex justify-content-between align-items-center"
                          >
                            {{ team.name }}
                            <b-badge variant="primary" pill>{{ team.game.name }}</b-badge>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Часовой пояс</label>
                      <ValidationProvider name="Часовой пояс" rules="required" v-slot="{ errors }">
                        <b-form-select
                          v-model="voteData.timezone"
                          :options="timezoneOptions"
                          :state="!errors.length ? null : false"
                        ></b-form-select>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Дата начала голосования</label>
                      <ValidationProvider name="Дата начала" rules="required" v-slot="{ errors }">
                        <DatePicker 
                          v-model="voteData.start_at" 
                          mode="dateTime" 
                          :model-config="modelConfig"
                          :min-date="new Date()"
                          is24hr
                          :popover="{ visibility: 'click' }"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="inputValue"
                              v-on="inputEvents"
                              :state="!errors.length ? null : false"
                            />
                          </template>
                        </DatePicker>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Дата окончания голосования</label>
                      <ValidationProvider name="Дата окончания" rules="required" v-slot="{ errors }">
                        <DatePicker 
                          v-model="voteData.end_at" 
                          mode="dateTime" 
                          :model-config="modelConfig"
                          :min-date="voteData.start_at"
                          is24hr
                          :popover="{ visibility: 'click' }"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="inputValue"
                              v-on="inputEvents"
                              :state="!errors.length ? null : false"
                            />
                          </template>
                        </DatePicker>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <b-button type="submit" variant="success" class="mt-4">Обновить голосование</b-button>
                  </b-form>
                </ValidationObserver>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { LanguageAdapterMixin } from "@/mixins/Language/LanguageAdapterMixin";
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'EditVote',
  mixins: [LanguageAdapterMixin],
  components: {
    DatePicker,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      voteData: {
        title: {
          ru: '',
          en: '',
          kz: '',
          uz: '',
        },
        description: {
          ru: '',
          en: '',
          kz: '',
          uz: '',
        },
        team_ids: [],
        start_at: '',
        end_at: '',
        timezone: 'Europe/Moscow',
      },
      teamSearch: '',
      searchResults: [],
      selectedTeams: [],
      timezoneOptions: [
        { value: 'Europe/Moscow', text: 'Moscow (GMT+3)' },
        { value: 'Europe/London', text: 'London (GMT+1)' },
        { value: 'America/New_York', text: 'New York (GMT-4)' },
        { value: 'Asia/Tokyo', text: 'Tokyo (GMT+9)' },
        { value: 'Asia/Dubai', text: 'Dubai (GMT+4)' },
        { value: 'Asia/Almaty', text: 'Almaty (GMT+6)' },
        { value: 'Asia/Tashkent', text: 'Tashkent (GMT+5)' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      teams: 'getTeams',
      currentVote: 'moduleVoting/getCurrentVote',
    }),
  },
  mounted() {
    this.fetchVoteData();
  },
  methods: {
    ...mapActions('moduleVoting', ['fetchVote', 'updateVote']),
    ...mapActions(['indexTeams']),
    formatDate(dateString) {
      return dateString ? dateString.slice(0, 16) : '';
    },
    async fetchVoteData() {
      try {
        await this.fetchVote(this.$route.params.id);
        const voteData = this.currentVote;
        if (voteData) {
          this.voteData = {
            title: voteData.title || {
              ru: '',
              en: '',
              kz: '',
              uz: '',
            },
            description: voteData.description || {
              ru: '',
              en: '',
              kz: '',
              uz: '',
            },
            team_ids: voteData.teams ? voteData.teams.map(team => team.id) : [],
            start_at: this.formatDate(voteData.start_at),
            end_at: this.formatDate(voteData.end_at),
            timezone: voteData.timezone || 'Europe/Moscow',
          };
          this.selectedTeams = voteData.teams || [];
          console.log('Fetched and processed vote data:', this.voteData);
        } else {
          console.error('Received empty vote data');
          this.$toast.error('Получены пустые данные голосования', { position: 'top-right', timeout: 2000 });
        }
      } catch (error) {
        console.error('Error fetching vote data:', error);
        this.$toast.error('Ошибка при загрузке данных голосования', { position: 'top-right', timeout: 2000 });
      }
    },
    async searchTeams() {
      if (this.teamSearch.length < 2) {
        this.searchResults = [];
        return;
      }
      
      try {
        await this.indexTeams({
          search: this.teamSearch,
          pageNumber: 1,
        });
        this.searchResults = this.teams;
      } catch (error) {
        console.error('Error searching teams:', error);
      }
    },
    addTeam(team) {
      if (!this.selectedTeams.some(t => t.id === team.id)) {
        this.selectedTeams.push(team);
        this.voteData.team_ids.push(team.id);
      }
      this.teamSearch = '';
      this.searchResults = [];
    },
    removeTeam(index) {
      this.selectedTeams.splice(index, 1);
      this.voteData.team_ids.splice(index, 1);
    },
    async submitVote() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      try {
        const formattedVoteData = {
          ...this.voteData,
          start_at: this.formatDate(this.voteData.start_at) + ':00',
          end_at: this.formatDate(this.voteData.end_at) + ':00',
        };

        console.log('Updating vote data:', formattedVoteData);
        const response = await this.updateVote({ id: this.$route.params.id, voteData: formattedVoteData });
        console.log('Vote updated:', response);
        this.$toast.success('Голосование успешно обновлено', { position: 'top-right', timeout: 2000 });
        this.$router.push({ name: 'list_votes' });
      } catch (error) {
        console.error('Error updating vote:', error);
        this.$toast.error('Ошибка при обновлении голосования', { position: 'top-right', timeout: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/star.scss";
</style>