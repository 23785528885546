import AddStageTournament from "@/components/modals/AddStageTournament.vue";
import EditStageTournament from "@/components/modals/EditStageTournament.vue";
import DeleteStageTournament from "@/components/modals/DeleteStageTournament.vue";
import AddGroupTournament from "@/components/modals/AddGroupTournament.vue";
import EditGroupTournament from "@/components/modals/EditGroupTournament.vue";
import DeleteGroupTournament from "@/components/modals/DeleteGroupTournament.vue";
import BasicDeleteTeamTournament from "@/components/modals/BasicDeleteTeamTournament.vue";
import MoveToNextStageTournament from "@/components/modals/MoveToNextStageTournament.vue";
import CompleteTournament from "@/components/modals/CompleteTournament.vue";
import ReviewStageTournament from "@/components/modals/ReviewStageTournament.vue";
import ReviewGroupTournament from "@/components/modals/ReviewGroupTournament.vue";
import DeleteTeamFromStageTournament from "@/components/modals/DeleteTeamFromStageTournament.vue";
import DeleteTeamFromTournament from "@/components/modals/DeleteTeamFromTournament.vue";
import GenerateGridForStageTournament from "@/components/modals/GenerateGridForStageTournament.vue";
import AddMatchForBook from "@/components/modals/AddMatchForBook.vue";
import EditMatchForBook from "@/components/modals/EditMatchForBook.vue";
import MoveToNextStageMLBBTournament from "@/components/modals/MoveToNextStageMLBBTournament.vue";
import TransferTeamToNextRound from "@/components/modals/TransferTeamToNextRound.vue";
import UserDocuments from "@/components/modals/UserDocuments.vue";
import DeleteTournament from "@/components/modals/DeleteTournament.vue";
import EditRoundTournament from "@/components/modals/EditRoundTournament.vue";
import DeleteFakeUser from "@/components/modals/DeleteFakeUser.vue";
import EditFakeUser from "@/components/modals/EditFakeUser.vue";
import AddRoundTournament from "@/components/modals/AddRoundTournament.vue";
import CreateFakeTeam from "@/components/modals/CreateFakeTeam.vue";
import DeleteFakeTeam from "@/components/modals/DeleteFakeTeam.vue";
import EditFakeTeam from "@/components/modals/EditFakeTeam.vue";
import BanUser from "@/components/modals/BanUser.vue";
import BanTeam from "@/components/modals/BanTeam.vue";
import CreateFakeUser from "@/components/modals/CreateFakeUser.vue";
import GenerateMatchesForOlympic from "@/components/modals/GenerateMatchesForOlympic.vue";
import AddMatchForOlympic from "@/components/modals/AddMatchForOlympic.vue";
import BasicDeleteTeamTournamentMlbb from "@/components/modals/BasicDeleteTeamTournamentMlbb.vue";
import RegenerateGridForStageTournament from "@/components/modals/RegenerateGridForStageTournament.vue";
// wow streamers
import BanWowStreamer from "@/components/modals/BanWowStreamer.vue";
import ConfirmUnbanWowStreamer from "@/components/modals/ConfirmUnbanWowStreamer.vue";
import ConfirmApproveWowStreamer from "@/components/modals/ConfirmApproveWowStreamer.vue";
import ConfirmRejectWowStreamer from "@/components/modals/ConfirmRejectWowStreamer.vue";
import DeactivateStream from "@/components/modals/DeactivateStream.vue";
// watch
import DeleteWatchVideo from "@/components/modals/DeleteWatchVideo.vue";
//mailings
import RunMailing from "@/components/modals/RunMailing.vue";
// vote
import DeleteVoteModal from "@/components/modals/DeleteVoteModal.vue";

const modals = new Map()

modals.set('AddStageTournament', AddStageTournament)
modals.set('EditStageTournament', EditStageTournament)
modals.set('DeleteStageTournament', DeleteStageTournament)
modals.set('AddGroupTournament', AddGroupTournament)
modals.set('EditGroupTournament', EditGroupTournament)
modals.set('DeleteGroupTournament', DeleteGroupTournament)
modals.set('BasicDeleteTeamTournament', BasicDeleteTeamTournament)
modals.set('MoveToNextStageTournament', MoveToNextStageTournament)
modals.set('CompleteTournament', CompleteTournament)
modals.set('ReviewStageTournament', ReviewStageTournament)
modals.set('ReviewGroupTournament', ReviewGroupTournament)
modals.set('DeleteTeamFromStageTournament', DeleteTeamFromStageTournament)
modals.set('DeleteTeamFromTournament', DeleteTeamFromTournament)
modals.set('GenerateGridForStageTournament', GenerateGridForStageTournament)
modals.set('AddMatchForBook', AddMatchForBook)
modals.set('EditMatchForBook', EditMatchForBook)
modals.set('MoveToNextStageMLBBTournament', MoveToNextStageMLBBTournament)
modals.set('TransferTeamToNextRound', TransferTeamToNextRound)
modals.set('UserDocuments', UserDocuments)
modals.set('DeleteTournament', DeleteTournament)
modals.set('EditRoundTournament', EditRoundTournament)
modals.set('DeleteFakeUser', DeleteFakeUser)
modals.set('EditFakeUser', EditFakeUser)
modals.set('AddRoundTournament', AddRoundTournament)
modals.set('CreateFakeTeam', CreateFakeTeam)
modals.set('DeleteFakeTeam', DeleteFakeTeam)
modals.set('EditFakeTeam', EditFakeTeam)
modals.set('BanUser', BanUser)
modals.set('BanTeam', BanTeam)
modals.set('CreateFakeUser', CreateFakeUser)
modals.set('GenerateMatchesForOlympic', GenerateMatchesForOlympic)
modals.set('AddMatchForOlympic', AddMatchForOlympic)
modals.set('BasicDeleteTeamTournamentMlbb', BasicDeleteTeamTournamentMlbb)
modals.set('RegenerateGridForStageTournament', RegenerateGridForStageTournament)
// wow streamers
modals.set('BanWowStreamer', BanWowStreamer)
modals.set('ConfirmUnbanWowStreamer', ConfirmUnbanWowStreamer);
modals.set('ConfirmApproveWowStreamer', ConfirmApproveWowStreamer);
modals.set('ConfirmRejectWowStreamer', ConfirmRejectWowStreamer);
modals.set('RunMailing', RunMailing);
modals.set('DeactivateStream', DeactivateStream)
// watch
modals.set('DeleteWatchVideo', DeleteWatchVideo)
// vote
modals.set('DeleteVoteModal', DeleteVoteModal)


export default modals